import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Tabs, Tab } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import "aos/dist/aos.css";
import nvda from "./assets/nvda.jpg";
import jaws from "./assets/jaws.jpg";
import "./BasicComputingCourse.css";
import { useEffect, useState } from "react";
import { getCourseWeeks, getcourses } from "./local_api/ApiService";

const AccessibilitySelf = () => {
    AOS.init();

    // data fetching function for localapi
    const id = 10;

    const [courseDetails, setCourseDetails] = useState(null);
    const [courseWeeks, setCourseWeeks] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const courses = await getcourses();
                const course = courses.find((course) => course.id === parseInt(id));

                if (course) {
                    setCourseDetails(course);

                    // Fetch weeks data for the course from ApiService
                    const weeks = await getCourseWeeks(id);
                    setCourseWeeks(weeks);
                } else {
                    setError("Course not found");
                }
            } catch (error) {
                setError("Error fetching data");
            }
        };

        fetchData();
    }, []);

    console.log('courses', courseDetails);
    console.log('weeks', courseWeeks);

    const sortWeeks = (weeks) => {
        return weeks.slice().sort((a, b) => {
            const weekA = a.week.toLowerCase();
            const weekB = b.week.toLowerCase();
            return weekA.localeCompare(weekB, undefined, { numeric: true, sensitivity: 'base' });
        });
    };

    return (
        <>
            <Navbar />

            {/* Slider start */}

            <section className="bgimage">
                <div className="container">
                    <div className="row par">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
                            <h1>{courseDetails?.course_name}</h1>
                            <br />
                            <p>
                                {courseDetails?.course_description}
                            </p>
                            <h3 className="mb-3">Upcoming Batch Details</h3>

                            <p>
                                Main Course: {courseDetails?.start_date}
                                <br />
                                Trial Class: {courseDetails?.trial_date}
                            </p>
                            <p>
                                <a href="#price" className="btn btn-primary btn-large">
                                    Course Fee »
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Slider end */}

            {/* course info start */}
            <section>
                <div id="parallax-world-of-ugg">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Course Overview</h2>
                        </div>
                        <div className="block">
                            <p>
                                At first, students will learn about the fundamentals of basic
                                computing. They will learn the office products such as word,
                                excel, and PowerPoint. They will also learn about different
                                types of OS such as Windows, Linux, and macOS. These skills are
                                very much required in the corporate world.
                                <br></br>
                                <br></br>
                                After that, students will learn about SDLC and Manual Testing.
                                Manual Testing is a type of software testing where testers
                                manually test the software application. Manual Testing is the
                                most primitive of all testing types and helps find bugs in the
                                software system. JIRA is an issue management tool and it helps
                                to facilitate the testing process and to manage various types of
                                issues in the software development process. Manual Testing does
                                not require knowledge of any testing tool.
                            </p>
                            {/* <p className="line-break margin-top-10" /> */}
                            <p className="margin-top-10">
                                Finally, students will learn about Accessibility Testing and
                                which is a type of software testing performed to ensure that the
                                application being tested is usable by people with disabilities
                                like hearing, color blindness, old age, and other disadvantaged
                                groups. Students will learn how to use assistive technologies
                                such as screen readers, screen magnifiers, and color contrast
                                analyzers to test the application both on the web and mobile in
                                regard to the WCAG guidelines and principles.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* course info end */}

            {/* Tab start */}

            <div className="container">
                <div className="row" data-aos="fade-up">
                    <div className="col-12">
                        <section className="payment">
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="tab-menu"
                            >
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="home"
                                    title="Course Information"
                                >
                                    <ul>
                                        {/* <li>
                                            Weekend Classes: {courseDetails?.weekend_classes} | New
                                            York Time
                                        </li> */}

                                        {/* <li>
                                            Student Support Session: {courseDetails?.student_support_session} |
                                            New York Time
                                        </li> */}
                                        <li>Start Date: {courseDetails?.start_date} (Weekend Batch)</li>
                                        <li>
                                            Duration: {courseDetails?.course_duration}.
                                            {/* <ul>
                                                <li>Regular Class: {courseDetails?.total_class_duration}</li>
                                                <li>Student Support Session: {courseDetails?.support_session_duration}</li>
                                            </ul> */}
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="profile"
                                    title="Who Can Join"
                                >
                                    <ul>
                                        <li>College degree holder from any discipline.</li>
                                        <li>
                                            Person with basic computer knowledge, Microsoft Windows
                                            operating system
                                        </li>
                                        <li>
                                            Person with good speaking, reading, writing and listening
                                            in English.
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="contact"
                                    title="Learning Outcome"
                                >
                                    <ul>
                                        <li>
                                            Students will be capable of performing accessibility
                                            testing on web and mobile applications.{" "}
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="check"
                                    title="Instruction Method"
                                >
                                    <ul>
                                        <li>Instructor led (Online only)</li>
                                    </ul>
                                </Tab>
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>

            {/* Tab end */}

            <section>
                <div class="accordion container" id="accordionExample">
                    <div className="section-title text-center">
                        <h2>Course Outline</h2>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                            >
                                Module 1: Basic Computing
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <p className="mt-5 fw-bold">Computer Fundamentals:</p>
                                <ul>
                                    <li>Computer Architecture including hardware and software</li>
                                    <li>Operating Systems Basics (Windows, macOS)</li>
                                    <li>Managing files in different Operating Systems</li>
                                    <li>Remote Desktop, Internet, VPN, Proxy Server</li>
                                    <li>Office Communication Tool such as Slack</li>
                                </ul>

                                <p className="mt-5 fw-bold">
                                    Google Suite/Microsoft (MS) Office:
                                </p>
                                <ul>
                                    <li>Google Docs/MS Word, Google Sheets/MS Excel</li>
                                    <li>Google Slides /MS PowerPoint, Gmail/MS Outlook</li>
                                </ul>

                                <p className="mt-5 fw-bold">Networking and Customer Service:</p>
                                <ul>
                                    <li>Networking Concepts, IP Addressing, DHCP</li>
                                    <li>Networking Commands such as ipconfig, ping, nslookup</li>
                                    <li>
                                        Customer Service Principles, Problem Solving, Effective
                                        Communication
                                    </li>
                                    <li>RCRM software such as HubSpot, JIRA</li>
                                </ul>

                                <p className="mt-5 fw-bold">Operating System:</p>
                                <ul>
                                    <li>
                                        Learn the basic functionalities inside an operating system
                                        such as the installation of software, creating a directory,
                                        printing features, value setup in environmental variables
                                    </li>
                                    <li>Linux Operating System Basics and Installation Guide</li>
                                    <li>Linux Commands such as pwd, mkdir, touch</li>
                                    <li>Overview on macOS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                Module 2: Manual Testing
                            </button>
                        </h2>
                        <div
                            id="collapseTwo"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <p className="fw-bold">Software Testing and SDLC:</p>
                                    <li>
                                        What is Software? Discussion on the importance of Software
                                        in our daily life.
                                    </li>
                                    <li>
                                        What is Software Testing? What are the principles of
                                        Software Testing?
                                    </li>
                                    <li>Software Testing Trends and Future Perspective</li>
                                    <li>
                                        Introduction to SDLC(Software Development Lifecycle).
                                        Discussion on the different phases of SDLC
                                        <ul>
                                            <li>Planning & Requirement Analysis</li>
                                            <li>Defining Requirements</li>
                                            <li>Designing the product</li>
                                            <li>Building or Developing the product</li>
                                            <li>Testing</li>
                                            <li>Deployment & Maintenance</li>
                                        </ul>
                                    </li>
                                    <li>Why testing is a crucial part of SDLC?</li>
                                </ul>

                                <ul>
                                    <p className="fw-bold mt-5">Various kind of Testing:</p>
                                    <li>
                                        Discussion of various kinds of Software Testing.
                                        <ul>
                                            <li>Dynamic Testing</li>
                                            <li>Static Testing</li>
                                            <li>Smoke Testing</li>
                                            <li>Functional Testing</li>
                                            <li>Regression Testing</li>
                                            <li>Back End Testing</li>
                                            <li>Black Box Testing</li>
                                            <li>White Box Testing</li>
                                            <li>Integration Testing</li>
                                            <li>System Testing</li>
                                            <li>User Acceptance Testing</li>
                                            <li>Performance Testing</li>
                                            <li>Security Testing</li>
                                        </ul>
                                    </li>
                                </ul>

                                <ul>
                                    <p className="fw-bold mt-5">SDLC Models:</p>
                                    <li>
                                        Discussion on SDLC Models (Waterfall Methodology and Agile
                                        Methodology).
                                        <ul>
                                            <li>Waterfall model Design</li>
                                            <li>
                                                Advantages and Disadvantages of the Waterfall model
                                            </li>
                                            <li>Agile model Design</li>
                                            <li>Advantages and Disadvantages of the Agile model</li>
                                        </ul>
                                    </li>
                                    <li>Introduction to Agile Scrum process.</li>
                                </ul>

                                <ul>
                                    <p className="fw-bold mt-5">
                                        Test Case and Defect Management:
                                    </p>
                                    <li>
                                        What is the Requirement? How to write a Requirement in an
                                        excel spreadsheet?
                                    </li>
                                    <li>
                                        What is User Story? How to write a User Story in an excel
                                        spreadsheet?
                                    </li>
                                    <li>
                                        What is a Test Case? How to write a Test case in an excel
                                        spreadsheet?
                                    </li>
                                    <li>What is RTM (Requirement Traceability Matrix)?</li>
                                    <li>
                                        Introduction to Defect management and Bugs Report.
                                        Introduction to Defect Life Cycle.
                                    </li>
                                </ul>

                                <ul>
                                    <p className="fw-bold mt-5"> JIRA Issue Management Tool:</p>
                                    <li>
                                        Introduction to JIRA issue management tool and Zephyr test
                                        management tool.
                                        <ul>
                                            <li>Create an epic</li>
                                            <li>Create a user story</li>
                                            <li>Create a test case</li>
                                            <li>Create a sprint</li>
                                            <li>
                                                Moving items from the product backlog to the sprint
                                                backlog
                                            </li>
                                            <li>Start a sprint</li>
                                            <li>
                                                The transition of a JIRA ticket in an active sprint
                                            </li>
                                            <li>Create a test cycle</li>
                                            <li>Execution of test case</li>
                                            <li>Create a defect</li>
                                        </ul>
                                    </li>
                                </ul>

                                <ul>
                                    <p className="fw-bold mt-5"> Exam:</p>
                                    <li>Exam (Written and Practical)</li>
                                    <li>Oral presentation test</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                Module 3: Accessibility Testing
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <li>
                                        Learn Basic HTML
                                        <ul>
                                            <li>What are HTML and CSS?</li>
                                            <li>What is HTML Tag?</li>
                                            <li>What is HTML Attribute?</li>
                                            <li>Practical exercise of simple webpage development.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul>
                                    <li>What is Accessibility Testing?</li>
                                </ul>
                                <ul>
                                    <li>Why is it important to perform Accessibility Testing?</li>
                                </ul>
                                <ul>
                                    <li>
                                        Which Disabilities to Support by Accessibility Testing?
                                        <ul>
                                            <li>Hearing/Auditory Disability</li>
                                            <li>Vision/Visual Disability</li>
                                            <li>Physical Disability</li>
                                            <li>Cognitive/Learning or Neurological Disability</li>
                                            <li>Literacy Disability</li>
                                            <li>Speech Disability</li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul>
                                    <li>Accountability for Testing for Accessibility.</li>
                                </ul>
                                <ul>
                                    <li> How to perform Accessibility Testing?</li>
                                    <ul>
                                        <li>Manual Testing</li>
                                        <li>Automated Testing</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>
                                        {" "}
                                        Introduction to various kinds of accessibility testing
                                        tools.
                                    </li>
                                    <ul>
                                        <li>Axe digital accessibility toolkit</li>
                                        <li>JAWS</li>
                                        <li>NVDA</li>
                                        <li>Voiceover</li>
                                        <li>Color Contrast Analyser</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>
                                        Comprehensive and practical demonstration of all the WCAG
                                        (Web Content Accessibility Guidelines) principles and
                                        guidelines.
                                    </li>
                                </ul>
                                <ul>
                                    <li>Keyboard Interaction</li>
                                    <ul>
                                        <li>
                                            Usages of Keyboard alone to conduct web accessibility
                                            testing.
                                        </li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>Screen Reader Interaction (Web & Mobile)</li>
                                    <ul>
                                        <li>
                                            Usages of various screen readers and keyboard shortcuts to
                                            conduct web and mobile accessibility testing.
                                        </li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>Screen Magnification (Web & Mobile)</li>
                                    <ul>
                                        <li>
                                            Usages of Screen Magnification techniques and tools to
                                            conduct web accessibility testing.
                                        </li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>Color Contrast</li>
                                    <ul>
                                        <li>
                                            Usages of Color Contrast Analysers and Developer tools to
                                            test color contrast ratio on web content.
                                        </li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>Switch Interaction (Mobile)</li>
                                    <ul>
                                        <li>
                                            Conduct accessibility testing on mobile applications using
                                            switch devices.
                                        </li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>Voice Access and Voice Control (Android, iOS)</li>
                                    <ul>
                                        <li>
                                            Conduct accessibility testing on mobile applications using
                                            voice-assisted techniques.
                                        </li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>
                                        Perform web accessibility testing using Axe digital
                                        accessibility toolkit
                                    </li>
                                    <ul>
                                        <li>
                                            Configuration of the tool in the Chrome browser extension
                                        </li>
                                        <li>Perform testing on the entire pages</li>
                                        <li>Perform testing for page content and widgets</li>
                                        <li>Perform Test for Keyboard Accessibility</li>
                                        <li>Perform Test for Meaning Conveyed with Color</li>
                                        <li>Perform Test Alt Text Quality</li>
                                        <li>Perform Test Video/Audio Accessibility</li>
                                        <li>Perform Tests for Landmarks</li>
                                        <li>Perform Test for Headings</li>
                                        <li>Perform Test Link Text Quality</li>
                                        <li>Perform Test Form Labels and Instructions</li>
                                        <li>Perform Test Form Validation</li>
                                        <li>Generate and export testing results</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>Writing Effective Accessibility Bug Tickets.</li>
                                </ul>
                                <ul>
                                    <li>Interview preparation and Exam.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <a
                                class="face-button"
                                href="/files/Brochure.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div class="face-primary">
                                    <span class="icon fa fa-download"></span>
                                    Course Details
                                </div>

                                <div class="face-secondary">
                                    <span class="icon fa fa-hdd-o"></span>
                                    Size: 4.80 MB
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section id="price" className="pricing">
                <div className="container">
                    <div className="section-title">
                        <h2>Course Fee</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-md-4">
                            <div className="card mb-5 mb-lg-0">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">
                                        Price
                                    </h5>

                                    <hr />
                                    <h5 className="card-title text-muted text-uppercase text-center">
                                        <a style={{ textDecoration: "none" }} href="/contact-us">
                                            Call Us
                                        </a>{" "}
                                        For Course Fees
                                    </h5>
                                    <hr />
                                    <ul className="fa-ul">
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            <strong>Lectures & Exams</strong>
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            MCQ Quizes
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            Practical
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            Question Answering
                                        </li>
                                    </ul>
                                    <div className="d-grid">
                                        <a
                                            href="/registration"
                                            className="btn btn-primary text-uppercase"
                                        >
                                            Enroll
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Price end */}

            {/* table start */}
            {/* <section>
                <div class="container">
                    <div className="section-title">
                        <h2>Current Current Course Schedule</h2>
                    </div>
                    <p>
                        <strong>Weekend Classes: </strong> {courseDetails?.weekend_classes} |
                        New York Time
                        <br></br>
                        <strong>Student Support Session: </strong> {courseDetails?.student_support_session} |
                        New York Time
                    </p>
                    <table class="table text-center">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Time</th>
                                <th scope="col">Date (Weekend Batch)</th>
                                <th scope="col">Modules</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortWeeks(courseWeeks).map((week, index) => (
                                    <tr key={week?.week}>
                                        <th scope="row"></th>
                                        <td>{week?.week} ({courseDetails?.class_duration})</td>
                                        <td>
                                            {week?.date_01}<br></br>
                                            {week?.date_02}
                                        </td>
                                        <td>{week?.module}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section> */}

            {/* table end */}

            {/* tools start */}

            <section>
                <div className="container">
                    <div className="section-title">
                        <h2>Tools</h2>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block">
                                    <h2>
                                        <i class="fas fa-tools"></i>
                                    </h2>
                                    <h5 className="card-title">Axe Dev Tools</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block">
                                    <h2>
                                        <i class="fab fa-windows mt-3"></i>
                                    </h2>
                                    <h5 className="card-title">Microsoft Office 365</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block">
                                    <h2>
                                        <i class="fa fa-microphone" aria-hidden="true"></i>
                                    </h2>
                                    <h5 className="card-title">Voiceover</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  my-4">
                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block">
                                    <img className="nvda" src={nvda} alt="nvda" />
                                    <h5 className="card-title">NVDA</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block">
                                    <h2>
                                        <i class="fab fa-jira mt-3"></i>
                                    </h2>
                                    <h5 className="card-title">Atlassian JIRA</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block">
                                    <img className="jaws" src={jaws} alt="jaws" />
                                    {/* <h5 className="card-title">Jaws</h5> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* tools end */}
            <Footer />
        </>
    );
};

export default AccessibilitySelf;