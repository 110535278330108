import { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { trialSubmit } from "./api";
import Navbar from "./Navbar";
import Footer from "./Footer";
import emailjs from 'emailjs-com';

import "aos/dist/aos.css";

import "./TrialClass.css";

function TrialClass() {
  const [firstNameRequired, setFirstNameRequired] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastNameRequired, setLastNameRequired] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailRequired, setEmailRequired] = useState("");
  const [email, setEmail] = useState("");
  const [phoneRequired, setPhoneRequired] = useState("");
  const [phone, setPhone] = useState("");
  const [source, setSource] = useState("");
  const [studentComment, setStudentComment] = useState("");
  const [enrollRequired, setEnrollRequired] = useState("");
  const [enroll, setEnroll] = useState([]);
  const [success, setSuccess] = useState("");
  const [successAlert, setSuccessAlert] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [captchaRequired, setCaptchaRequired] = useState(false);
  const handleOnChange = (value) => {
    console.log("Captcha:" + value);
    setCaptcha(true);
  };

  // send mail funtion....................................................................

  // Function to get the first two words of the program name
  function getFirstTwoWords(text) {
    return text.split(' ').slice(0, 2).join(' ');
  }
  const shortenedProgramInterested = getFirstTwoWords(enroll.toString());

  const sendEmail = async (data) => {
    const templateParams = {
      ...data,
    };

    try {
      const result = await emailjs.send(
        'service_p6h972r',
        'template_61pzwsb',
        templateParams,
        'DBnVXFzCdt42lUxb2'
      );
      console.log(result.text);
      // Email sent successfully
    } catch (error) {
      console.error(error.text);
      // Error occurred while sending email
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const formValidation = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (firstName.length === 0) {
      setFirstNameRequired("First name is Required");
    } else {
      setFirstNameRequired("");
    }
    if (lastName.length === 0) {
      setLastNameRequired("Last name is Required");
    } else {
      setLastNameRequired("");
    }

    if (email.length === 0) {
      setEmailRequired("Email address is Required");
    } else {
      setEmailRequired("");
    }
    if (phone.length === 0) {
      setPhoneRequired("Phone number is Required");
    } else {
      if (/[^0-9a-zA-Z+]/.test(phone)) {
        setPhoneRequired("Phone number must be Alphanumeric");
      } else {
        setPhoneRequired("");
      }
    }
    var flag = false;
    enroll.map((e) => {
      if (e.length > 0) {
        flag = true;
      }
    });
    if (!flag) {
      setEnrollRequired("Course is Required");
    } else {
      setEnrollRequired("");
    }

    if (!captcha) {
      setCaptchaRequired("Please solve the captcha");
    } else {
      setCaptchaRequired("");
    }

    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      enroll.length !== 0 &&
      captcha
    ) {
      const data = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        email: email,
        phone_number: phone,
        source: source,
        student_comment: studentComment,
        program_interested: enroll.toString(),
      };
      // .....................................................................................
      // Constructing the email template data
      const emailTemplateData = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        email: email,
        phone_number: phone,
        source: source,
        student_comment: studentComment,
        program_interested: enroll.toString(),
        program_interested_shortened: shortenedProgramInterested,
      };


      try {
        // Call the sendEmail function with the email template data
        try {
          sendEmail(emailTemplateData);
          console.log(emailTemplateData);
        } catch (error) {
          console.error('Error sending email:', error);
        }

        // .....................................................................................

        await trialSubmit(data, (temp) => {
          if (temp.status === 201) {
            setSuccess("Thanks for submitting");
            setSuccessAlert("formSuccess");
          } else if (temp.status === 409) {
            setSuccess("Your record already exists in the system. Please contact us for further details.");
            setSuccessAlert("formFailed");
          }
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 10000);

      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false); // Set loading to false after form submission error
      }
    } else {
      setIsLoading(false); // Set loading to false when form is not submitted
    }
  };


  useEffect(() => {
    if (phone.length === 0) {
      setPhoneRequired("");
    } else {
      if (/[^0-9a-zA-Z+]/.test(phone)) {
        setPhoneRequired("Phone number must be Alphanumeric");
      } else {
        setPhoneRequired("");
      }
    }
  }, [phone]);
  return (
    <>
      <Navbar course="active" />

      {success && (
        <>
          <div className="success-box">
            <p className={successAlert}>{success}</p>
          </div>
        </>
      )}
      <div id="contact" className="registration-form">
        {!success && (
          <>
            <div className="left top-line">
              <div className="title-box">
                <h1>Please fill up the trial class form</h1>
              </div>
            </div>
            <div className=" d-flex align-items-stretch right ">
              <form onSubmit={formValidation} className="php-email-form">
                <p className={successAlert}>{success}</p>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="firstname">* First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="firstname"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <p className="inputRequired">{firstNameRequired}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label for="middlename"> Middle Name</label>
                    <input
                      type="text"
                      name="middlename"
                      className="form-control"
                      id="middlename"
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label for="lastname">* Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      className="form-control"
                      id="lastname"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <p className="inputRequired">{lastNameRequired}</p>
                  </div>
                  <div className="form-group col-md-6 mt-3 mt-md-0">
                    <label for="name">* Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="inputRequired">{emailRequired}</p>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="phone">* Phone number</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <p className="inputRequired">{phoneRequired}</p>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label for="referral">
                    How did you hear about us? Please mention the name of your
                    referral source.
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="referral"
                    id="referral"
                    onChange={(e) => setSource(e.target.value)}
                  />
                </div>
                <hr></hr>
                <div className="form-group mt-3">
                  <label htmlFor="name">*Trial Class you want to join:</label>
                  {/* <br />
                  <input
                    type="radio"
                    id="vehicle1"
                    name="enroll"
                    value="Accessibility Testing"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle1">
                    Accessibility Testing
                  </label> */}
                  <br />
                  <input
                    type="radio"
                    id="vehicle2"
                    name="enroll"
                    value="Software Testing with Selenium"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle2">
                    Software Testing with Selenium
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="vehicle4"
                    name="enroll"
                    value="Data Analysis with Python"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle4">
                    Data Analysis with Python
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="vehicle5"
                    name="enroll"
                    value="Software Testing - Self-paced"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle5">
                    Software Testing - Self-paced
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="vehicle6"
                    name="enroll"
                    value="Accessibility Testing - Self-paced"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle6">
                    Accessibility Testing - Self-paced
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="vehicle7"
                    name="enroll"
                    value="Data Analysis with Python - Self-paced"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle7">
                    Data Analysis with Python - Self-paced
                  </label>
                  <br />
                  {/* <input
                    type="radio"
                    id="vehicle3"
                    name="enroll"
                    value="Data Analysis with Power BI & SQL Server"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="vehicle3">
                    Data Analysis with Power BI & SQL Server
                  </label>
                  <br /> */}
                  {/* <br />
                  <input
                    type="radio"
                    id="course4"
                    name="enroll"
                    value="Cybersecurity for Professionals | Comprehensive CompTIA Security+ Certification (SY0-601)"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check cyber" for="course4">
                    Cybersecurity for Professionals | Comprehensive CompTIA
                    Security+ Certification (SY0-601)
                  </label> */}

                  <p className="inputRequired">{enrollRequired}</p>
                </div>
                <hr></hr>
                <div className="form-group mt-3">
                  <label for="name">Your Comment</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="10"
                    onChange={(e) => setStudentComment(e.target.value)}
                  ></textarea>
                </div>
                <ReCAPTCHA
                  sitekey="6LefNQEhAAAAAOhj4yy-VNVLgprB4ey5NjJ2RX7K"
                  onChange={handleOnChange}
                />
                <p className="inputRequired">{captchaRequired}</p>

                <div className="text-center">
                  <button type="submit" disabled={isLoading}>
                    {isLoading ?
                      <div>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> </span>loading...
                      </div>
                      : "SUBMIT"}
                  </button>
                </div>

              </form>
            </div>
          </>
        )}
      </div>

      <Footer />
    </>
  );
}

export default TrialClass;
