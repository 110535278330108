import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import NewsPage from "./NewsPage";
import ReadMore from "./ReadMore";
import ContactPage from "./ContactPage";
import Registration from "./Registration";
import Payment from "./Payment";
import CoursesPage from "./CoursesPage";
import TrialClass from "./TrialClass";
import Team from "./Team";
import Instructor from "./Instructor";

import SoftwareTestingCourse from "./SoftwareTestingCourse";
import DataAnalysis from "./DataAnalysis";
import CertificatePage from "./CertificatePage";
import Career from "./Career";
import Gallery from "./Gallery02";
import GalleryMainPage from "./GalleryMainPage";
import Events from "./Events";
import Media from "./Media";
import JobDescription from "./JobDescription";
import CyberSecurity from "./CyberSecurity";
import axios from "axios";

// bootstrap js
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Accessibility from "./Accessibility";
import Privacy from "./Privacy";
import DataAnalysisWithPython from "./DataAnalysisWithPython";
import Acknowledgment from "./Acknowledgment";
import SoftwareTestingCourseSelf from "./SoftwareTestingCourseSelf";
import AccessibilitySelf from "./AccessibilitySelf";
import DataAnalysisWithPythonSelf from "./DataAnalysisWithPythonSelf";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/trial-class" element={<TrialClass />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/readmore" element={<ReadMore />} />
        <Route path="/team" element={<Team />} />
        <Route path="/instructor" element={<Instructor />} />

        {/* <Route path="/accessibility-testing" element={<Accessibility />} /> */}
        <Route path="/accessibility-testing" element={<CoursesPage />} />

        <Route
          path="/software-testing-with-selenium"
          element={<SoftwareTestingCourse />}
        />
        <Route path="/data-analysis" element={<DataAnalysis />} />
        <Route path="/certificate" element={<CertificatePage />} />
        <Route path="/career" element={<Career />} />
        <Route path="/mediaandgallery" element={<GalleryMainPage />} />
        <Route path="/imagegallery" element={<Gallery />} />
        <Route path="/events" element={<Events />} />
        <Route path="/media" element={<Media />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/acknowledgment" element={<Acknowledgment />} />
        {/* <Route
          path="/career/business-support-manager"
          element={<JobDescription />}
        /> */}
        <Route
          path="/cyber-security-for-professionals"
          element={<CyberSecurity />}
        />
        <Route
          path="/data-analysis-with-python"
          element={<DataAnalysisWithPython />}
        />

        <Route path="/accessibility-testing-self" element={<AccessibilitySelf />} />
        <Route path="/software-testing-with-selenium-self" element={<SoftwareTestingCourseSelf />} />
        <Route path="/data-analysis-with-python-self" element={<DataAnalysisWithPythonSelf />} />
      </Routes>
    </Router>
  );
}
export default App;
